import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Grid, Row, Col } from 'react-flexbox-grid'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import Preview from '../../components/Preview'
import BodyColor from '../../components/BodyColor'
import WorkTitle from '../../components/WorkTitle'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'Sheffield Refractories Website',
  type: 'Site',
  client: 'Sheffield Refractories',
  path: '/works/web/shtr/',
  date: '2012-09-09',
  image: 'shtr-demo',
  favorite: true,
  related: '/works/print/shtr-ad/',
}

const related = [
  {
    title: frontmatter.type,
    href: frontmatter.path,
  },
  {
    title: 'Print',
    href: frontmatter.related,
  },
]

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/shtr/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1100, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />
      <BodyColor isDark={true} />

      <WorkTitle title={frontmatter.title} related={related} />

      <p className="aligncenter text-center">
        Design the web presence of an established industrial company.
      </p>

      <figure className="text-center">
        <Preview
          fluid={findImage(images, 'shtr-home')}
          url="Home Page"
          alt="Home page of Sheffield Refractories"
          isDark={true}
        />
      </figure>

      <p className="aligncenter text-center">
        SHTR was prepearing an expansion to the CIS region and needed a website.{' '}
        <br />
        They wanted to launch big and make and impression on the market.
      </p>

      <br />
      <br />

      <div style={{ background: '#666' }}>
        <br />
        <br />

        <Grid>
          <Row>
            <Col md={5} lg={5}>
              <h2>Products</h2>
              <p>
                SHTR sells refractories equipment. Detailed product description
                pages were created to fully illustrate each product and their
                technical specifications.
              </p>

              <p>
                The look and feel of the product pages was carried over to the
                print brochures as well:
              </p>
              <Img
                fluid={findImage(images, 'shtr-brochure')}
                alt="Brochure"
                style={{ width: '80%' }}
              />
            </Col>
            <Col md={7} lg={6}>
              <Preview
                fluid={findImage(images, 'shtr-product')}
                url="Product page"
                alt="Product page design"
              />
            </Col>
            <Col lg={1}></Col>
          </Row>

          <Row>
            <Col sm={12}>
              
            </Col>
          </Row>
        </Grid>

        <WorkTitleBlock {...frontmatter} />
      </div>
    </Layout>
  )
}
